import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import {registerLicense} from '@syncfusion/ej2-base'
import Modal from 'react-modal';
Modal.setAppElement('#root'); // Adjust if your root element has a different ID
registerLicense('MzQ2NDY3NUAzMjMwMmUzMjJlMzBVWnc2N2IwUDI3RnI1SjJBUFAwTjdyVGE3M2tlNEtJMGNIVDhWZVJjZVlVPQ==;MzQ2NDY3NkAzMjMwMmUzMjJlMzBZTTlvc1dBcC9ObnhpcWF5VlVDRWpMa1BVNk9YQWQrNEgrNXpKMWEzc3J3PQ==;Mgo+DSMBaFt/QHJqVVhjWlpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQFljQH5bd0BhWX5YdXNdRg==;Mgo+DSMBPh8sVXJ0S0R+XE9HcFRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS3pTf0VrWHpccHVTQ2dUUQ==;ORg4AjUWIQA/Gnt2VVhiQlFadVlJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxXdkxjUX5bcnRUQGVVUkI=;NRAiBiAaIQQuGjN/V0Z+Xk9EaFxEVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RckVqWXdfdXZVR2ZaUEx+;MzQ2NDY4MUAzMjMwMmUzMjJlMzBRaFFXWU8rMklmUWovZ2dseW1tN1diam1Edkd3QUlGV2EwbFVhSVc0ajRzPQ==;MzQ2NDY4MkAzMjMwMmUzMjJlMzBIL1g2ZXJ0L0w4S0YrRVNwclJlTnI0ZmxmUlNGYVJqQmI0eVB3ejhkNFowPQ==;Mgo+DSMBMAY9C3t2VVhiQlFadVlJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxXdkxjUX5bcnRUQGZbUEM=;MzQ2NDY4NEAzMjMwMmUzMjJlMzBDWEVMOTUvbThjVEgvdENMdkYwQVl1NGVjY1pUV3puZGtRcytUZGF1OFh3PQ==;MzQ2NDY4NUAzMjMwMmUzMjJlMzBVYTFTS1MvbFVvSVFiN2dMQXhlajlPY2NpdTBQRzlhZjZJNTRvMHgwRzBBPQ==;MzQ2NDY4NkAzMjMwMmUzMjJlMzBRaFFXWU8rMklmUWovZ2dseW1tN1diam1Edkd3QUlGV2EwbFVhSVc0ajRzPQ==')
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
