import React, { useState, useEffect } from "react";
import { UserAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import logo from '../images/logopng.png';
import './Navbar.css';
import Modal from 'react-modal';
import { db } from '../firebase'; // Adjust the path as per your project structure
import { doc, getDoc, setDoc } from 'firebase/firestore';




export default function NavigationBar() {
  const { user, logout } = UserAuth();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [registracijeLink, setRegistracijeLink] = useState('');
  const [servisiLink, setServisiLink] = useState('');

  useEffect(() => {
    // Fetch the links from Firestore when the component mounts
    const fetchLinks = async () => {
      try {
        const registracijeDoc = await getDoc(doc(db, 'settings', 'registracije'));
        const servisiDoc = await getDoc(doc(db, 'settings', 'servisi'));

        if (registracijeDoc.exists()) {
          setRegistracijeLink(registracijeDoc.data().link);
        }

        if (servisiDoc.exists()) {
          setServisiLink(servisiDoc.data().link);
        }
      } catch (error) {
        console.error('Error fetching links:', error);
      }
    };

    fetchLinks();
  }, []);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
      console.log('You are logged out');
    } catch (e) {
      console.log(e.message);
    }
  };

  const openLink = (link) => {
    if (link) {
      window.open(link, '_blank');
    } else {
      alert('Link nije postavljen.');
    }
  };

  const handleSaveLinks = async () => {
    try {
      // Save the links to Firestore
      await setDoc(doc(db, 'settings', 'registracije'), { link: registracijeLink });
      await setDoc(doc(db, 'settings', 'servisi'), { link: servisiLink });
      setIsModalOpen(false);
      alert('Linkovi su uspješno spremljeni.');
    } catch (error) {
      console.error('Error saving links:', error);
      alert('Greška pri spremanju linkova.');
    }
  };

  return (
    <nav className="navbar">
      <a href="/" className="logo-link">
        <img src={logo} className="logo" alt="STEFMARK LOGO" />
      </a>
      <div className="nav-links-wrapper">
        <div className="nav-links">
          <a href="/" className="nav-button">TIMELINE</a>
          <a href="/najmovi" className="nav-button">UREDI NAJMOVE</a>
          <a href="/dnevniplan" className="nav-button">DNEVNI PLAN</a>
          <a href="/partneri" className="nav-button">PARTNERI</a>
          <button className="nav-button" onClick={() => openLink(registracijeLink)}>REGISTRACIJE</button>
          <button className="nav-button" onClick={() => openLink(servisiLink)}>SERVISI</button>
        </div>
      </div>
      <div className="nav-buttons-right">
        <button type="button" onClick={handleLogout} className="nav-button logout-button">
          ODJAVA
        </button>
        <button className="nav-button settings-button" onClick={() => setIsModalOpen(true)}>POSTAVKE</button>
      </div>

      {/* Modal for settings */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Postavke"
        className="modal"
        overlayClassName="overlay"
      >
        <h2>Postavke</h2>
        <div className="form-group">
          <label>Registracije Link:</label>
          <input
            type="text"
            value={registracijeLink}
            onChange={(e) => setRegistracijeLink(e.target.value)}
            placeholder="Unesite link za Registracije"
          />
        </div>
        <div className="form-group">
          <label>Servisi Link:</label>
          <input
            type="text"
            value={servisiLink}
            onChange={(e) => setServisiLink(e.target.value)}
            placeholder="Unesite link za Servisi"
          />
        </div>
        <div className="modal-buttons">
          <button onClick={handleSaveLinks} className="save-button">Spremi</button>
          <button onClick={() => setIsModalOpen(false)} className="cancel-button">Zatvori</button>
        </div>
      </Modal>
    </nav>
  );
}