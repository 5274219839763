import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { TimelineViews,HeaderRowDirective,HeaderRowsDirective, TimelineMonth, ScheduleComponent, ViewsDirective, ViewDirective, ResourcesDirective, ResourceDirective, Inject, Resize , DragAndDrop } from '@syncfusion/ej2-react-schedule';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { ChipListComponent, ChipsDirective, ChipDirective } from '@syncfusion/ej2-react-buttons';
import { extend, createElement, Internationalization, L10n, Ajax  } from '@syncfusion/ej2-base';
import { DataManager, Query, ReturnOption, UrlAdaptor , ODataV4Adaptor  } from '@syncfusion/ej2-data';
import { TextBox , NumericTextBox } from '@syncfusion/ej2-react-inputs';
import './Timeline.css'
import {getCars, resourceData, CarConverter} from '../data/resourceData'
import { collection, getDocs , addDoc, updateDoc, setDoc , deleteDoc, doc, query, where } from "firebase/firestore";
import {db , database} from '../firebase';
import { loadCldr } from '@syncfusion/ej2-base';
import './hr.json'
import Avatar from '@mui/material/Avatar';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import * as numberingSystems from "./culture/numberingSystems.json";
import * as gregorian from "./culture/ca-gregorian.json";
import * as numbers from "./culture/numbers.json";
import * as timeZoneNames from "./culture/timeZoneNames.json";

import {boje , avatarSize , theme_color} from  "../data/values";
loadCldr(numberingSystems, gregorian, numbers, timeZoneNames);

L10n.load({ hr: {
    "schedule": {
        "day": "Dan",
        "week": "Tjedan",
        "workWeek": "Radni tjedan",
        "month": "Mjesec",
        "year": "Godina",
        "agenda": "dnevni red",
        "weekAgenda": "Tjedni dnevni red",
        "workWeekAgenda": "Agenda radnog tjedna",
        "monthAgenda": "Mjesečna agenda",
        "today": "Danas",
        "noEvents": "Nema događaja",
        "emptyContainer": "Za ovaj dan nema zakazanih događaja.",
        "allDay": "Cijeli dan",
        "start": "Početak",
        "end": "Kraj",
        "more": "više",
        "close": "Zatvoriti",
        "cancel": "Otkazati",
        "noTitle": "(Bez naslova)",
        "delete": "Izbrisati",
        "deleteEvent": "Ovaj događaj",
        "deleteMultipleEvent": "Brisanje više događaja",
        "selectedItems": "Odabrane stavke",
        "deleteSeries": "Cijela serija",
        "edit": "Uredi",
        "editSeries": "Cijela serija",
        "editEvent": "Ovaj događaj",
        "createEvent": "Stvoriti",
        "subject": "predmet",
        "addTitle": "Dodaj Naslov",
        "moreDetails": "Više detalja",
        "save": "Spremi",
        "editContent": "Kako biste željeli promijeniti termin u seriji?",
        "deleteContent": "Jeste li sigurni da želite izbrisati ovaj događaj?",
        "deleteMultipleContent": "Jeste li sigurni da želite izbrisati odabrane događaje?",
        "newEvent": "Novi događaj",
        "title": "Naslov",
        "location": "Mjesto",
        "description": "Opis",
        "timezone": "Vremenska zona",
        "startTimezone": "Početna vremensku zonu",
        "endTimezone": "Kraj vremenska zona",
        "repeat": "Ponoviti",
        "saveButton": "Spremiti",
        "cancelButton": "Otkazati",
        "deleteButton": "Izbrisati",
        "recurrence": "Ponavljanje",
        "wrongPattern": "Obrazac ponavljanja nije valjan.",
        "seriesChangeAlert": "Želite li otkazati promjene učinjene na određenim primjercima ove serije i ponovno ih uskladiti s cijelom serijom?",
        "createError": "Trajanje događaja mora biti kraće od onog koliko često se događaj događa. Skratite trajanje ili promijenite obrazac ponavljanja u uređivaču događaja ponavljanja.",
        "sameDayAlert": "Dvije pojave istog događaja ne mogu se dogoditi istog dana.",
        "occurenceAlert": "Ne može se zakazati pojava ponavljajućeg sastanka ako preskoči kasniju pojavu istog sastanka.",
        "editRecurrence": "Uređivanje ponavljanja",
        "repeats": "ponavlja",
        "alert": "obavijest",
        "startEndError": "Odabrani datum završetka događa se prije datuma početka.",
        "invalidDateError": "Unesena vrijednost datuma nije važeća.",
        "blockAlert": "Događaji se ne mogu zakazati unutar blokiranog vremenskog raspona.",
        "ok": "U redu",
        "yes": "Da",
        "no": "Ne",
        "occurrence": "događaj",
        "series": "Niz",
        "previous": "prijašnji",
        "next": "Sljedeći",
        "timelineDay": "Dan",
        "timelineWeek": "Tjedan",
        "timelineWorkWeek": "Radni tjedan",
        "timelineMonth": "Mjesec ",
        "timelineYear": "Vremenska linija",
        "editFollowingEvent": "Slijedite događaje",
        "deleteTitle": "Izbriši događaj",
        "editTitle": "Uređivanje događaja",
        "beginFrom": "Počnite od",
        "endAt": "Kraj ",
        "expandAllDaySection": "Proširi-cjelodnevni odjeljak",
        "collapseAllDaySection": "Sažmi cjelodnevni odjeljak",
        "searchTimezone": "Traži vremensku zonu",
        "noRecords": "Nije pronađen nijedan zapis"
    },
    "recurrenceeditor": {
        "none": "nijedan",
        "daily": "Dnevno",
        "weekly": "Tjedni",
        "monthly": "Mjesečno",
        "month": "Mjesec",
        "yearly": "Godišnje",
        "never": "Nikada",
        "until": "Do",
        "count": "Računati",
        "first": "Prvi",
        "second": "Drugi",
        "third": "treći",
        "fourth": "Četvrta",
        "last": "Posljednji",
        "repeat": "Ponoviti",
        "repeatEvery": "Ponovite svaki",
        "on": "Ponovite Uključeno",
        "end": "Kraj",
        "onDay": "Dan",
        "days": "Dan (a)",
        "weeks": "Tjedni",
        "months": "Mjesec (i)",
        "years": "Godine",
        "every": "svaki",
        "summaryTimes": "Vrijeme",
        "summaryOn": "na",
        "summaryUntil": "do",
        "summaryRepeat": "ponavlja",
        "summaryDay": "dan (a)",
        "summaryWeek": "tjedni",
        "summaryMonth": "mjesec (i)",
        "summaryYear": "godine",
        "monthWeek": "Mjesečni tjedan",
        "monthPosition": "Mjesečna pozicija",
        "monthExpander": "Mjesečni ekspander",
        "yearExpander": "Godine Expander",
        "repeatInterval": "Interval ponovite"
    }
}});



class Timeline extends React.Component {
    constructor() {
        super(...arguments);
        //console.log("Start");
        this.items = ['REZERVACIJA', 'TRENUTNI NAJAM', 'SERVIS', 'REGISTRACIJA'];
        this.test = [];
        this.originalEventData = {};
        this.tipoviNajma = [];
        //this.resourceData = resourceData
        this.state = {
             isLoading: true ,
             selected: this.items.map(() => false),
             selectedResourceTypes: [],  // Za praćenje aktivnih filtera tipova resursa
             showAvailableOnly: false,   // Za filtraciju slobodnih resursa
             resourceData: [],           // Svi dohvaćeni resursi
             filteredResources: [],      // Filtrirani resursi koji će se prikazivati
             events: this.test,  // Store the original event list
             filteredEvents: this.test, 
             availableRentals: {},
             currentAction: ''
        }
        this.state2 = {
            startDate: '',
            endDate: ''
        };
        this.resourceData = [];
        
        this.instance = new Internationalization();
        this.customClass = 'e-calendar-blue';
        
        

        let scheduleObj;
        let eventTypeObj;
        let titleObj;
        let notesObj;

        let firstNajamEvent;
        let firstRegistracijaEvent;
        let firstBookingEvent;
        let eventsList;
        let startDate;
        let endDate;
        let year;
        let month;
    }
    


    GuidFun() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }




    async onActionBegin(args) {
        if (args.requestType == "eventChange") {
            
            
            let eventData = args.changedRecords[0];
            
            /*if (eventData.IsAllDay){
                console.log(eventData)
            }*/
            //console.log(args.requestType)
            /*if (eventData.IsAllDay ) {
                let endTime = new Date(eventData.EndTime);
                endTime.setDate(endTime.getDate() - 1); // Oduzimanje jednog dana
                endTime.setHours(12, 0, 0); // Postavi vrijeme na 11:30 PM
                eventData.EndTime = endTime;
            }*/
            
            if (eventData.DocumentId === undefined) {
                const querySnapshot = await getDocs(query(collection(db, "EventData"), where("Id", "==", eventData.Id)));
    
                if (!querySnapshot.empty) {
                    querySnapshot.forEach((doc) => {
                        eventData.DocumentId = doc.id;
                    });
                } else {
                    console.error("Dokument sa datim Id nije pronađen u bazi.");
                    return; 
                }
            }
    
            const defaultValues = {
                Id: eventData.Id !== undefined ? eventData.Id : 0,
                CarId: eventData.CarId !== undefined ? eventData.CarId : 0,
                IsAllDay: eventData.IsAllDay !== undefined ? eventData.IsAllDay : true,
                Subject: eventData.Subject !== undefined ? eventData.Subject : "",
                EndTime: eventData.EndTime !== undefined ? eventData.EndTime : new Date(),
                StartTime: eventData.StartTime !== undefined ? eventData.StartTime : new Date(),
                Location: eventData.Location !== undefined ? eventData.Location : "",
                RecurrenceRule: eventData.RecurrenceRule !== undefined ? eventData.RecurrenceRule : null,
                Description: eventData.Description !== undefined ? eventData.Description : "",
                Status: eventData.Status !== undefined ? eventData.Status : "Booking",
                kontaktBroj: eventData.kontaktBroj !== undefined ? eventData.kontaktBroj : "",
                brojPonude: eventData.brojPonude !== undefined ? eventData.brojPonude : "",
                price: eventData.price !== undefined ? eventData.price : "",
                polog: eventData.polog !== undefined ? eventData.polog : "",
                pocetak: eventData.pocetak !== undefined ? eventData.pocetak : "",
                kraj: eventData.kraj !== undefined ? eventData.kraj : "",
                linkRacuna: eventData.linkRacuna !== undefined ? eventData.linkRacuna : "",
                EndTimezone: eventData.EndTimezone !== undefined ? eventData.EndTimezone : null,
                StartTimezone: eventData.StartTimezone !== undefined ? eventData.StartTimezone : null,
                RecurrenceException: eventData.RecurrenceException !== undefined ? eventData.RecurrenceException : null,
                RecurrenceID: eventData.RecurrenceID !== undefined ? eventData.RecurrenceID : null
            };
    
            // Ažuriramo eventData sa podrazumevanim vrednostima
            eventData = { ...defaultValues, DocumentId: eventData.DocumentId };
            await updateDoc(doc(db, "EventData", eventData.DocumentId), eventData);
    
        } else if (args.requestType == "eventCreate") {
            let guid = (
                this.GuidFun() +
                this.GuidFun() +
                "-" +
                this.GuidFun() +
                "-4" +
                this.GuidFun().substr(0, 3) +
                "-" +
                this.GuidFun() +
                "-" +
                this.GuidFun() +
                this.GuidFun() +
                this.GuidFun()
            ).toLowerCase();
            args.data[0].DocumentId = guid.toString();
    
            let eventData = args.data[0];
    
            // Provjeravamo da li akcija nije 'drag' ili 'resize'
            /*if (eventData.IsAllDay) {
                let endTime = new Date(eventData.EndTime);
                endTime.setDate(endTime.getDate() - 1); // Oduzimanje jednog dana
                endTime.setHours(23, 59, 0); // Postavljanje kraja na 11:30 PM
                eventData.EndTime = endTime;
            }*/
    
            const defaultValues = {
                Id: eventData.Id !== undefined ? eventData.Id : 0,
                CarId: eventData.CarId !== undefined ? eventData.CarId : 0,
                IsAllDay: eventData.IsAllDay !== undefined ? eventData.IsAllDay : true,
                Subject: eventData.Subject !== undefined ? eventData.Subject : "",
                EndTime: eventData.EndTime !== undefined ? eventData.EndTime : new Date(),
                StartTime: eventData.StartTime !== undefined ? eventData.StartTime : new Date(),
                Location: eventData.Location !== undefined ? eventData.Location : "",
                RecurrenceRule: eventData.RecurrenceRule !== undefined ? eventData.RecurrenceRule : null,
                Description: eventData.Description !== undefined ? eventData.Description : "",
                Status: eventData.Status !== undefined ? eventData.Status : "Booking",
                kontaktBroj: eventData.kontaktBroj !== undefined ? eventData.kontaktBroj : "",
                brojPonude: eventData.brojPonude !== undefined ? eventData.brojPonude : "",
                price: eventData.price !== undefined ? eventData.price : "",
                polog: eventData.polog !== undefined ? eventData.polog : "",
                pocetak: eventData.pocetak !== undefined ? eventData.pocetak : "",
                kraj: eventData.kraj !== undefined ? eventData.kraj : "",
                linkRacuna: eventData.linkRacuna !== undefined ? eventData.linkRacuna : "",
                EndTimezone: eventData.EndTimezone !== undefined ? eventData.EndTimezone : null,
                StartTimezone: eventData.StartTimezone !== undefined ? eventData.StartTimezone : null,
                RecurrenceException: eventData.RecurrenceException !== undefined ? eventData.RecurrenceException : null,
                RecurrenceID: eventData.RecurrenceID !== undefined ? eventData.RecurrenceID : null
            };
    
            // Ažurirajte eventData sa podrazumevanim vrednostima
            eventData = { ...defaultValues };
    
            setDoc(doc(db, "EventData", guid), eventData);
    
        } else if (args.requestType == "eventRemove") {
            //console.log(args.deletedRecords[0]);
            let eventData = args.deletedRecords[0];
            if (eventData.DocumentId === undefined) {
                const querySnapshot = await getDocs(query(collection(db, "EventData"), where("Id", "==", eventData.Id)));
    
                if (!querySnapshot.empty) {
                    querySnapshot.forEach((doc) => {
                        eventData.DocumentId = doc.id;
                    });
                } else {
                    console.error("Dokument sa datim Id nije pronađen u bazi.");
                    return; 
                }
            }

            
            deleteDoc(doc(db, "EventData", eventData.DocumentId)).then(() => {
                console.log("deleted");
            }).catch((error) => {
                console.error("Error deleting document: ", error);
            });
        }
    }
    
    

    async getData(){
        //console.log("Podatci");
        while(this.resourceData.length!==0) {
            this.resourceData.pop();
        }
    
        var querySnapshot = await getDocs(collection(db, "Cars").withConverter(CarConverter));
        querySnapshot.forEach((doc) => {
            this.resourceData.push(doc.data());
        });

        // Kreiraj set jedinstvenih tipova resursa
        let uniqueTypes = [...new Set(this.resourceData.map(car => car.vrsta))];

        uniqueTypes.sort((a, b) => a.localeCompare(b));
        this.tipoviNajma = uniqueTypes;
        // Postavi jedinstvene tipove u state
        this.setState({ 
            resourceData: this.resourceData, 
            filteredResources: this.resourceData,  // Svi resursi se inicijalno prikazuju
            uniqueResourceTypes: uniqueTypes 
        });
    
    
        const dataRef = collection(db, "EventData");
        await getDocs(dataRef).then(async querySnapshot => {
            const data = querySnapshot.docs.map(doc => doc.data());
            this.test = data;
            let length = this.test.length;
            for (let i = 0; i < length; i++) {
                let endTime = this.test[i].EndTime.seconds.toString() + "000";
                let srtTime = this.test[i].StartTime.seconds.toString() + "000";
                this.test[i].StartTime = new Date(parseInt(srtTime));
                this.test[i].EndTime = new Date(parseInt(endTime));
            }
    
            // Update the event status in the Firestore database
            for (let i = 0; i < this.test.length; i++) {
                let data = this.test[i];
                let newStatus = null;
                if (data.StartTime.getTime() < new Date().getTime() && data.EndTime.getTime() < new Date().getTime()) {
                    newStatus = 'Gotovo';
                } 
                
                // If the status changed, update it in the database
                if (newStatus && data.Status !== newStatus) {
                    data.Status = newStatus;
                    const querySnapshot = await getDocs(query(collection(db, 'EventData'), where('Id', '==', data.Id)));
                    if (!querySnapshot.empty) {
                        const document = querySnapshot.docs[0]; // Taking the first matching document
                        const docId = document.id;
                        updateDoc(doc(db, "EventData", docId), {
                            Status: newStatus
                        });
                    
                }
            }}
    
            this.setState({
                events: this.test,
                filteredEvents: this.test,
            });
        });
    
        this.resourceData.sort((a,b) => {
            if (a.sifraNajma < b.sifraNajma) {
                return -1;
            }
            if (a.sifraNajma > b.sifraNajma) {
                return 1;
            }
            return 0;
        });
        
        //this.getAvailableRentalsCount();
        this.setState( {isLoading: false} );
    }
    
    
    getFirstNajamEvent = () => {
        /*console.log(this.test.sort((a, b) => new Date(a.StartTime) - new Date(b.StartTime))
        .find(event => event.Status === 'Booking' && new Date(event.StartTime) > new Date()))*/
        const event = this.test.sort((a, b) => new Date(a.StartTime) - new Date(b.StartTime))
            .find(event => event.Status === 'Booking' && new Date(event.StartTime) > new Date());
        if(event){
            event.startDate = this.instance.formatDate(event.StartTime, { type: 'date', skeleton: 'yMd', format: 'dd/MM/yyyy'  })
            return event.startDate.toString()
        }
        return 'Nema Rezervacija';
    }
    
    getFirstRegistracijaEvent = () => {
        const event = this.test.sort((a, b) => new Date(a.StartTime) - new Date(b.StartTime))
        .find(event => event.Status === 'Reg' && new Date(event.StartTime) > new Date());
        if(event){
            event.startDate = this.instance.formatDate(event.StartTime, { type: 'date', skeleton: 'yMd', format: 'dd/MM/yyyy'  })
            return event.startDate.toString() 
        }
        return 'Nema registracija';
    }
    
    getFirstBookingEvent = () => {
        const event = this.test.sort((a, b) => new Date(b.endTime) - new Date(a.endTime))
            .find(event => event.Status === 'Unajmu' && new Date(event.EndTime) > new Date());
        if(event){
            event.startDate = this.instance.formatDate(event.EndTime, { type: 'date', skeleton: 'yMd', format: 'dd/MM/yyyy'  })
            return event.startDate.toString()
        }
            
        return 'Nema najmova';
    }

    componentDidMount() {
        // Call the getData method that fetches both cars and events
        this.getData().then(() => {
            // Once data is fetched and set in state, calculate available rentals
            const counts = this.getAvailableRentalsCount();
            this.setState({ availableRentals: counts });
        }).catch((error) => {
            console.error('Error fetching data:', error);  // Log any fetch errors
        });
    }
    
    
    
    onChangeDatePicker(args) {
        //console.log("DESILO SE")
        this.scheduleObj.selectedDate = args.startDate;
        this.interval = args.daySpan;
        this.scheduleObj.views = [{ option: 'TimelineDay', interval: this.interval }];
        this.scheduleObj.dataBind();
        
    }

    getCarName(value) {
        return value.resourceData.text;
    }

    getCarLevel(value) {
        return value.resourceData.desc;
    }

    getCarVrsta(value) {
        return value.resourceData.vrsta;
    }

    getCarImg(value){
        return value.resourceData.imgLink;
    }

    getCarExcelLink(value){
        return value.resourceData.excelLink;
    }

    resourceHeaderTemplate(props) {
        return (
            <div className="template-wrap">
                <div className="resource-detail">
                    <div className="resource-name">{this.getCarName(props)}</div>
                    <div className="resource-desc">{this.getCarLevel(props)}</div>
                    <div className="resource-desc">{this.getCarVrsta(props)}</div>
                </div>
    
                <div className="resource-img">
                    <a target='_blank' href={this.getCarExcelLink(props)}>
                        <Avatar sx={{ width: avatarSize, height: avatarSize}} alt="Car Image" src={this.getCarImg(props)} />
                    </a>
                </div>
            </div>
        );
    }
    

    dateHeaderTemplate(args) {
        const dayOfWeek = args.date.getDay();
        
        // Check if the date is a weekend (Saturday or Sunday)
        if (dayOfWeek === 0 || dayOfWeek === 6) {  // 0 = Sunday, 6 = Saturday
            return (
                <div style={{ fontWeight: 'bold' }}>
                    {args.date.toLocaleDateString('hr-HR', { weekday: 'short', day: 'numeric', month: 'short' })}
                </div>
            );
        }
        return (
            <div>
                {args.date.toLocaleDateString('hr-HR', { weekday: 'short', day: 'numeric', month: 'short' })}
            </div>
        );
    }
    



     onPopupClose = (args) => {

        if (args.type === 'Editor') {

          const eventDetails = args.data;

          if (eventDetails && eventDetails.hasOwnProperty('IsAllDay') && eventDetails.IsAllDay) {

            const startTime = new Date(eventDetails.StartTime);

            const endTime = new Date(eventDetails.EndTime);

            startTime.setHours(0, 0, 0);

            endTime.setDate(endTime.getDate() - 1);

            endTime.setHours(23, 59, 0);

            eventDetails.StartTime = startTime;

            eventDetails.EndTime = endTime;

          }

        }

      }
    

    

    onPopupOpen(args) {
        
        
        if (args.type === 'Editor') {
            if (!args.element.querySelector('.custom-field-row0')) {
                let row = createElement('div', { className: 'custom-field-row0' });
                let formElement = args.element.querySelector('.e-schedule-form');
                formElement.firstChild.insertBefore(row, formElement.firstChild.firstChild);
                let container = createElement('div', { className: 'custom-field-container' });
                let inputEle = createElement('input', {
                  className: 'e-field', attrs: { name: 'EventType' }
                });
                container.appendChild(inputEle);
                row.appendChild(container);
    
                // Calculate the difference in days between startTime and endTime
                let startTimeInput = formElement.querySelectorAll('input[name="StartTime"]');
                let endTimeInput = formElement.querySelectorAll('input[name="EndTime"]');
                let startTime = new Date(startTimeInput[0].value);
                let endTime = new Date(endTimeInput[0].value);
                let timeDiff = Math.abs(endTime.getTime() - startTime.getTime());
                let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    
                let textBox = new TextBox({
                  value: `Duration: ${diffDays} day(s)`,
                  floatLabelType: 'Always', placeholder: 'Counter Days'
                });
                textBox.appendTo(inputEle);
                inputEle.setAttribute('name', 'EventType');
    
                // Get the instances of the date pickers
                let startTimePicker = startTimeInput[0].ej2_instances[0];
                let endTimePicker = endTimeInput[0].ej2_instances[0];
    
                // Add event listeners to startTime and endTime input elements
                startTimePicker.change = updateDuration;
                endTimePicker.change = updateDuration;
    
                function updateDuration() {
                  startTime = new Date(startTimePicker.value);
                  endTime = new Date(endTimePicker.value);
                  timeDiff = Math.abs(endTime.getTime() - startTime.getTime());
                  diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
                  textBox.value = `Broj dana: ${diffDays} dana`;
                  textBox.dataBind(); // Update the TextBox value
                }
              }
            
            

            if (!args.element.querySelector('.custom-field-row')) {
                let row = createElement('div', { className: 'custom-field-row' });
                let formElement = args.element.querySelector('.e-schedule-form');
                formElement.firstChild.insertBefore(row, formElement.firstChild.firstChild);
                let container = createElement('div', { className: 'custom-field-container' });
                let inputEle = createElement('input', {
                    className: 'e-field', attrs: { name: 'Status' }
                });
                container.appendChild(inputEle);
                row.appendChild(container);
                let drowDownList = new DropDownList({
                    dataSource: [
                        { text: 'Rezervacija', value: 'Booking' },
                        { text: 'U najmu', value: 'Unajmu' },
                        { text: 'Registracija', value: 'Reg' },
                        { text: 'Servis', value: 'Service' }
                    ],
                    fields: { text: 'text', value: 'value' },
                    value: args.data.Status,
                    floatLabelType: 'Always', placeholder: 'Status'
                });
                drowDownList.appendTo(inputEle);
                inputEle.setAttribute('name', 'Status');
            }

            if (!args.element.querySelector('.custom-field-row1')) {
                let row = createElement('div', { className: 'custom-field-row1' });
                let formElement = args.element.querySelector('.e-schedule-form');
                formElement.firstChild.insertBefore(row, formElement.firstChild.firstChild);
                let container = createElement('div', { className: 'custom-field-container1' });
                let inputEle = createElement('input', {
                    className: 'e-field', attrs: { name: 'brojPonude' }
                });
                container.appendChild(inputEle);
                row.appendChild(container);
                let textBoxComponent = new TextBox({
                    placeholder:"Račun/Ponuda",
                    value: this.props.brojPonude ? this.props.brojPonude : "",
                    floatLabelType: "Auto",
                    onChange: {},
                });
                textBoxComponent.appendTo(inputEle);
                inputEle.setAttribute('name', 'brojPonude');
            }

            if (!args.element.querySelector('.custom-field-row2')) {
                let row = createElement('div', { className: 'custom-field-row2' });
                let formElement = args.element.querySelector('.e-schedule-form');
                formElement.firstChild.insertBefore(row, formElement.firstChild.firstChild);
                let container = createElement('div', { className: 'custom-field-container2' });
                let inputEle = createElement('input', {
                    className: 'e-field', attrs: { name: 'kontaktBroj' }
                });
                container.appendChild(inputEle);
                row.appendChild(container);
                let textBoxComponent1 = new TextBox({
                    placeholder:"Kontakt Broj",
                    value: this.props.kontaktBroj ? this.props.kontaktBroj : "",
                    floatLabelType: "Auto",
                    onChange: {},
                });
                textBoxComponent1.appendTo(inputEle);
                inputEle.setAttribute('name', 'kontaktBroj');
            }

            if (!args.element.querySelector('.custom-field-row3')) {
                let row = createElement('div', { className: 'custom-field-row3' });
                let formElement = args.element.querySelector('.e-schedule-form');
                formElement.firstChild.insertBefore(row, formElement.firstChild.firstChild);
                let container = createElement('div', { className: 'custom-field-container3' });
                let inputEle = createElement('input', {
                    className: 'e-field', attrs: { name: 'price' }
                });
                container.appendChild(inputEle);
                row.appendChild(container);
                let textBoxComponent1 = new TextBox({
                    placeholder:"Cijena",
                    value: this.props.price ? this.props.price : "",
                    floatLabelType: "Auto",
                    onChange: {},
                });
                textBoxComponent1.appendTo(inputEle);
                inputEle.setAttribute('name', 'price');
            }

            if (!args.element.querySelector('.custom-field-row4')) {
                let row = createElement('div', { className: 'custom-field-row4' });
                let formElement = args.element.querySelector('.e-schedule-form');
                formElement.firstChild.insertBefore(row, formElement.firstChild.firstChild);
                let container = createElement('div', { className: 'custom-field-container4' });
                let inputEle = createElement('input', {
                    className: 'e-field', attrs: { name: 'linkRacuna' }
                });
                container.appendChild(inputEle);
                row.appendChild(container);
                let textBoxComponent1 = new TextBox({
                    placeholder:"Link Računa",
                    value: this.props.linkRacuna ? this.props.linkRacuna : "",
                    floatLabelType: "Auto",
                    onChange: {},
                });
                textBoxComponent1.appendTo(inputEle);
                inputEle.setAttribute('name', 'linkRacuna');
            }

            if (!args.element.querySelector('.custom-field-row5')) {
                let row = createElement('div', { className: 'custom-field-row5' });
                let formElement = args.element.querySelector('.e-schedule-form');
                formElement.firstChild.insertBefore(row, formElement.firstChild.firstChild);
                let container = createElement('div', { className: 'custom-field-container5' });
                let inputEle = createElement('input', {
                    className: 'e-field', attrs: { name: 'polog' }
                });
                container.appendChild(inputEle);
                row.appendChild(container);
                let textBoxComponent1 = new TextBox({
                    placeholder:"Polog",
                    value: this.props.polog ? this.props.polog : "",
                    floatLabelType: "Auto",
                    onChange: {},
                });
                textBoxComponent1.appendTo(inputEle);
                inputEle.setAttribute('name', 'polog');
            }

            if (!args.element.querySelector('.custom-field-row6')) {
                let row = createElement('div', { className: 'custom-field-row6' });
                let formElement = args.element.querySelector('.e-schedule-form');
                formElement.firstChild.insertBefore(row, formElement.firstChild.firstChild);
                let container = createElement('div', { className: 'custom-field-container6' });
                let inputEle = createElement('input', {
                    className: 'e-field', attrs: { name: 'kraj' }
                });
                container.appendChild(inputEle);
                row.appendChild(container);
                let textBoxComponent1 = new TextBox({
                    placeholder:"Kraj",
                    value: this.props.kraj ? this.props.kraj : "",
                    floatLabelType: "Auto",
                    onChange: {},
                });
                textBoxComponent1.appendTo(inputEle);
                inputEle.setAttribute('name', 'kraj');
            }

            if (!args.element.querySelector('.custom-field-row7')) {
                let row = createElement('div', { className: 'custom-field-row7' });
                let formElement = args.element.querySelector('.e-schedule-form');
                formElement.firstChild.insertBefore(row, formElement.firstChild.firstChild);
                let container = createElement('div', { className: 'custom-field-container7' });
                let inputEle = createElement('input', {
                    className: 'e-field', attrs: { name: 'pocetak' }
                });
                container.appendChild(inputEle);
                row.appendChild(container);
                let textBoxComponent1 = new TextBox({
                    placeholder:"Početak",
                    value: this.props.pocetak ? this.props.pocetak : "",
                    floatLabelType: "Auto",
                    onChange: {},
                });
                textBoxComponent1.appendTo(inputEle);
                inputEle.setAttribute('name', 'pocetak');
            }
        }
        if (args.type === "QuickInfo") {
            let popupObj = (args.element).ej2_instances[0];
            popupObj.open = function(args) {
              popupObj.refreshPosition();
            };
        }
    }


    

    onEventRendered(args) {

        let data = args.data;
        const eventElement = args.element;
    
        args.element.classList.add('zaobljeno');
            
        switch (args.data.Status) {
            case 'Booking':
                  args.element.style.backgroundColor = boje.REZERVACIJA;
                break;
            case 'Unajmu':
                    args.element.style.backgroundColor = boje.NAJAM;
                break;
            case 'Reg':
                    args.element.style.backgroundColor = boje.REGISTRACIJA;
                break;
            case 'Service':
                    args.element.style.backgroundColor = boje.SERVIS;
                break;
            case 'Gotovo':
                    args.element.style.backgroundColor = boje.GOTOVO;
                break;
        }
    }

    onRenderCell(args) {
        if (args.elementType == 'workCells' || args.elementType == 'monthCells') {
            let today = new Date(new Date().setHours(0,0,0,0)).getTime();
            let cellDate = new Date(args.date.setHours(0,0,0,0)).getTime();
            if(cellDate === today){
                args.element.classList.add('today-bg');
             }
        }
        if (args.elementType === 'dateHeader' && this.scheduleObj.currentView === 'TimelineMonth') {
            let ele = document.createElement('div');
            ele.innerHTML = " " +  this.getDateHeaderText(args.date);
            (args.element).appendChild(ele.firstChild);
        }
    }


    contentTemplate(props) {
        return (
            <div className="quick-info-content">
                
                <div className="event-content">
                    <div className="velko">
                        <label>Klijent</label>: 
                        <span> {props.Subject}</span>
                    </div>
                    <div className="velko">
                        <label>Račun/Ponuda</label>:
                        <span> {props.brojPonude}</span>
                    </div>
                    <div className="velko">
                        <label>Link Računa</label>:
                        <a target='_blank' href={props.linkRacuna}> <span>{props.linkRacuna} </span></a>
                    </div>
                    <div className="velko">
                        <label>Polog</label>:
                        <span> {props.polog}</span>
                    </div>
                    <div className="velko">
                        <label>Cijena</label>:
                        <span> {props.price}</span>
                    </div>
                    <div className="velko">
                        <label>Period</label>:
                        <span> {this.getHeaderDetails(props)}</span>
                    </div>
                    <div className="velko">
                        <label>Početak</label>:
                        <span> {props.pocetak}</span>
                    </div>
                    <div className="velko">
                        <label>Kraj</label>:
                        <span> {props.kraj}</span>
                    </div>
                    <div className="velko">
                        <label>Kontakt</label>:
                        <span> {props.kontaktBroj}</span>
                    </div>
                    <div className="velko">
                        <label>Bilješka</label>:
                        <span> {props.Description}</span>
                    </div>
                </div>
            </div>);
      }
    
    getHeaderDetails(data) {
        return this.instance.formatDate(data.StartTime, { type: 'date', skeleton: 'yMd', format: 'dd/MM/yyyy'  }) + " " + "-" + " " +
        this.instance.formatDate(data.EndTime, { type: 'date', skeleton: 'yMd' , format: 'dd/MM/yyyy' });
    }

    onDateChange(args) {
        console.log(args.daySpan)
        if(args.startDate != null){
            this.scheduleObj.selectedDate = args.startDate;
            this.scheduleObj.views[0].interval = args.daySpan;
            this.scheduleObj.dataBind();
        }
    }

    globalSearch(args) {
        let searchString = args.target.value;
        if (searchString !== '') {
            new DataManager(this.scheduleObj.getEvents(null, null, true)).executeQuery(new Query().
                search(searchString, ['Subject', 'Location', 'Description'], null, true, true)).then((e) => {
                    if (e.result.length > 0) {
                        this.showSearchEvents('show', e.result);
                    }
                    else {
                        this.showSearchEvents('hide');
                    }
                });
        }
        else {
            this.showSearchEvents('hide');
        }
    }

    showSearchEvents(type, data) {
        if (type === 'show') {
            this.scheduleObj.eventSettings.dataSource = data;
        }
        else {
            this.scheduleObj.eventSettings.dataSource = this.test;
        }
    }
    

    handleChangeForm = (e) => {
        const { name, value } = e.target;
        if (name === 'year') {
          this.year = value;
        } else {
          this.month = value;
        }
    };

    handleClickChip = (index) => {
        // Create a new array with the selection state toggled at the clicked index
        const newSelected = this.state.selected.slice();
        newSelected[index] = !newSelected[index];
        this.setState({ selected: newSelected }, this.filterEvents);
    };
    
    filterEvents = () => {
        const statusMap = {
            0: "Booking",      // Rezervacija
            1: "Unajmu",       // Trenutni Najam
            2: "Service",      // Servis
            3: "Reg",          // Registracija
        };
    
        let selectedStatuses = [];
        for (let i = 0; i < this.state.selected.length; i++) {
            if (this.state.selected[i]) {
                selectedStatuses.push(statusMap[i]);
            }
        }
    
        // Filter events by selected statuses
        let filteredEvents = this.test;
        if (selectedStatuses.length > 0) {
            filteredEvents = this.test.filter(event =>
                selectedStatuses.includes(event.Status)
            );
        }
    
        // Get the CarId of the filtered events
        const carIds = filteredEvents.map(event => event.CarId);
    
        // Filter resources based on those CarIds
        const filteredResources = this.state.resourceData.filter(resource =>
            carIds.includes(resource.id)
        );
    
        // Update the state with the new filtered events and filtered resources
        this.setState({ 
            filteredEvents: filteredEvents, 
            filteredResources: filteredResources 
        });
    };
    

    

    eventTemplate(props) {
        return (
            <div className="template-oblast" >
                <div className="event-left">
                    <span>{'\u00A0'}{'\u00A0'}{props.Subject}{';\u00A0'}</span> <span>{props.brojPonude}</span>
                </div>
                <div className="event-right">
                    <span>{props.Description}</span>
                </div>
            </div>
        );
    }
    
    
    handleResourceTypeFilter(type) {
        const { selectedResourceTypes } = this.state;
    
        //console.log("Odabrani tipovi prije promjene:", selectedResourceTypes);
    
        if (selectedResourceTypes.includes(type)) {
           // console.log(`Uklanjam tip: ${type}`);
            this.setState({
                selectedResourceTypes: selectedResourceTypes.filter(t => t !== type)
            }, () => {
                //console.log("Odabrani tipovi nakon uklanjanja:", this.state.selectedResourceTypes);
                this.filterResources();
            });
        } else {
            //console.log(`Dodajem tip: ${type}`);
            this.setState({
                selectedResourceTypes: [...selectedResourceTypes, type]
            }, () => {
                //console.log("Odabrani tipovi nakon dodavanja:", this.state.selectedResourceTypes);
                this.filterResources();
            });
        }
    }
    
    
    
    
    toggleAvailableFilter() {
        //console.log("Prebacujem status slobodnih danas:", !this.state.showAvailableOnly);
        this.setState({ showAvailableOnly: !this.state.showAvailableOnly }, () => {
            //console.log("Status slobodnih danas nakon promjene:", this.state.showAvailableOnly);
            this.filterResources();
        });
    }
    
    
    filterResources = () => {
        const { selectedResourceTypes, showAvailableOnly, resourceData, events } = this.state;
        let filteredResources = resourceData;
    
        // Filtriraj po odabranim tipovima resursa
        if (selectedResourceTypes.length > 0) {
            filteredResources = filteredResources.filter(resource =>
                selectedResourceTypes.includes(resource.vrsta)
            );
            //console.log("Resursi nakon filtracije po tipu:", filteredResources);
        }
    
        // Filtriraj po slobodnim resursima na današnji dan
        if (showAvailableOnly) {
            const today = new Date();
            filteredResources = filteredResources.filter(resource => {
                // Provjeri je li resurs zauzet na današnji dan
                const isAvailable = !events.some(event => {
                    const startTime = new Date(event.StartTime);
                    const endTime = new Date(event.EndTime);
                    return event.CarId === resource.id &&
                        ((startTime <= today && endTime >= today));  // Događaj traje danas
                });
                return isAvailable;
            });
            //console.log("Resursi nakon filtracije 'Slobodni danas':", filteredResources);
        }
    
        // Ažuriraj stanje s filtriranim resursima
        this.setState({ filteredResources }, () => {
            //console.log("Konačno filtrirani resursi:", this.state.filteredResources);
        });
    }

    onResizeStart = (args) => {
        // Capture original event data at the start of the resize operation
        this.originalEventData = { ...args.data };
      };
    
    onResizeStop = (args) => {
        const originalEventData = this.originalEventData;
        const originalStart = new Date(originalEventData.StartTime);
        const originalEnd = new Date(originalEventData.EndTime);
        const newStart = new Date(args.data.StartTime);
        const newEnd = new Date(args.data.EndTime);
      
        const message = `Produžujete/Skraćujete najam:
        Početak: ${originalStart.toLocaleString()} 
        Kraj: ${originalEnd.toLocaleString()} 
        do:
        Početak: ${newStart.toLocaleString()} 
        Kraj: ${newEnd.toLocaleString()}.
        Želite li potvrditi?`;
      
        const confirmResize = window.confirm(message);
      
        if (confirmResize) {
          console.log("Resize confirmed", args);
          // Proceed with the resize logic (update the event)
        } else {
          console.log("Resize canceled");
          // Revert the changes by resetting the event back to its original data
          args.data = originalEventData; // Restore original event data
        }
      };
      
       getResourceDetails = (resourceId) => {
        return this.resourceData.find(resource => resource.id === resourceId) || {};
        
      };

      onDragStart = (args) => {
        // Capture original event data at the start of the drag operation 
        this.originalEventData = { ...args.data }; 
      };


       onDragStop = (args) => {
        const originalEventData = this.originalEventData;
        const originalStart = new Date(originalEventData.StartTime);
        const originalEnd = new Date(originalEventData.EndTime);
        const newStart = new Date(args.data.StartTime);
        const newEnd = new Date(args.data.EndTime);
      
        // Get resource details
        const originalResource = this.getResourceDetails(originalEventData.CarId); // Use ResourceId to find original resource
        const newResource = this.getResourceDetails(args.data.CarId); // Use ResourceId to find new resource
        const message = `Pomičete event:
        Od najma: ${originalResource.text} (${originalResource.vrsta})
        (Početak: ${originalStart.toLocaleString()} - Kraj: ${originalEnd.toLocaleString()})
        Do najma: ${newResource.text} (${newResource.vrsta})
        (Početak: ${newStart.toLocaleString()} - Kraj: ${newEnd.toLocaleString()}).
        Želite li potvrditi promjene?`;
      
        const confirmDrag = window.confirm(message);
      
        if (confirmDrag) {
          console.log("Drag confirmed", args);
          // Proceed with the drag logic (update the event)
        } else {
          console.log("Drag canceled");
          // Revert the changes by resetting the event back to its original data
          args.data = originalEventData;
        }
      };

      
      
      getAvailableRentalsCount = () => {
        const today = new Date();
        const events = this.test;
        // Initialize the rental type counts dynamically based on tipoviNajma
        const rentalTypes = this.tipoviNajma.reduce((acc, tip) => {
            acc[tip] = 0; // Set count to 0 initially for each type
            return acc;
        }, {});
       // console.log(events)
        this.resourceData.forEach(resource => {
            // Check if the resource is available today
            const isAvailable = !events.some(event => {
                const startTime = new Date(event.StartTime);
                const endTime = new Date(event.EndTime);
                return event.CarId === resource.id && (startTime <= today && endTime >= today);
            });
    
            // If the resource is available, increment its type's count
            if (isAvailable && rentalTypes[resource.vrsta] !== undefined) {
                rentalTypes[resource.vrsta]++;
            }
        });
    
        return rentalTypes;
    };
    
    


    render() {
        const { availableRentals } = this.state;
        
        if(this.state.isLoading === true)
        {
            
            return (<p>UČITAVAM</p>);
        }
        if(window.innerWidth <= 500)
        {
            return <div id="centar">

            <div className="redovi">
                    <p>Prvi najam počinje: {this.firstNajamEvent}</p>
                    <p>Prva registracija počinje: {this.firstRegistracijaEvent}</p>
                    <p>Prvi najam završava: {this.firstBookingEvent}</p>
                </div>
                
            </div>
        }
        //console.log(this.state.filteredEvents);
        return <div id="centar">
            
        <br></br>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', float: "left", width: "70%", marginBottom: "25px" }}>

                <div className='custom-daterange'>
                    <DateRangePickerComponent
                        id="daterangepicker"
                        locale="hr"
                        placeholder="DATUM"
                        change={this.onDateChange.bind(this)}
                    />
                </div>

                <div style={{
                    borderLeft: '1px solid #000000',
                    alignSelf: 'stretch',
                    margin: '0 20px',
                }} />

                <input
                    className="e-input"
                    type="text"
                    placeholder="TRAŽI..."
                    onKeyUp={this.globalSearch.bind(this)}
                    style={{
                        borderRadius: '12px',
                        borderWidth: '2px',
                        borderStyle:'solid',
                        borderColor: theme_color,
                        backgroundColor: 'transparent',
                        color: 'black',
                        padding: '10px',
                        margin: '5px',
                        width: '10%'
                    }} />

                <div style={{
                    borderLeft: '1px solid #000000',
                    alignSelf: 'stretch',
                    margin: '0 20px',
                }} />


                {this.state.selected.map((isSelected, index) => (
                    <div
                        key={index}
                        style={{
                            display: 'flex',        
                            alignItems: 'center',   
                            justifyContent: 'center', 
                            cursor: 'pointer',
                            borderRadius: '12px',
                            borderWidth: '2px',
                            borderStyle: isSelected ? 'none' : 'solid', // Conditional border style
                            borderColor: isSelected ? 'none' : theme_color, // Conditional border color
                            backgroundColor: isSelected ? theme_color : 'transparent', // Conditional background color
                            color: 'black',
                            padding: '10px',
                            margin: '5px',
                        }}
                        onClick={() => this.handleClickChip(index)}
                    >
                        {this.items[index]}
                    </div>
                ))}


                <div style={{
                    borderLeft: '1px solid #000000',
                    alignSelf: 'stretch',
                    margin: '0 20px',
                }} />

                 {/* Gumbi za filtriranje po tipovima resursa */}
                    {this.state.uniqueResourceTypes && this.state.uniqueResourceTypes.map((rentalType, index) => (
                        <div
                            key={index}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                                borderRadius: '12px',
                                borderWidth: '2px',
                                borderStyle: this.state.selectedResourceTypes.includes(rentalType) ? 'none' : 'solid',
                                borderColor: this.state.selectedResourceTypes.includes(rentalType) ? 'none' : theme_color,
                                backgroundColor: this.state.selectedResourceTypes.includes(rentalType) ? theme_color : 'transparent',
                                color: 'black',
                                padding: '10px',
                                margin: '5px',
                            }}
                            onClick={() => this.handleResourceTypeFilter(rentalType)}
                        >
                            {rentalType}
                        </div>
                    ))}

                    {/* Gumb za prikaz slobodnih najmova */}
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            borderRadius: '12px',
                            borderWidth: '2px',
                            borderStyle: this.state.showAvailableOnly ? 'none' : 'solid',
                            borderColor: this.state.showAvailableOnly ? 'none' : theme_color,
                            backgroundColor: this.state.showAvailableOnly ? theme_color : 'transparent',
                            color: 'black',
                            padding: '10px',
                            margin: '5px',
                        }}
                        onClick={() => this.toggleAvailableFilter()}
                    >
                        SLOBODNI DANAS
                    </div>

                
            </div>



        

        

    
    <ScheduleComponent className="custom-schedule1" showAllDayRow={false} key={`${this.state.filteredResources.length}-${this.state.filteredEvents.length}`} width='100%' height='850px'  currentView='TimelineDay'  locale="hr" selectedDate={new Date(new Date().getTime() - (1 * 24 * 60 * 60 * 1000))} dateFormat="dd/MM/yyyy"  ref={schedule => (this.scheduleObj = schedule)} quickInfoOnSelectionEnd={true}  dateHeaderTemplate={this.dateHeaderTemplate} renderCell= {this.onRenderCell.bind(this)} cssClass="schedule-cell-dimension" resourceHeaderTemplate={this.resourceHeaderTemplate.bind(this)} actionBegin={this.onActionBegin.bind(this)} 
        eventSettings={{ template: this.eventTemplate.bind(this), dataSource: this.state.filteredEvents  , fields: {
                id: 'Id',
                subject: { name: 'Subject', title: 'Klijent' },
                description: { name: 'Description', title: 'Bilješka' },
                startTime: { name: 'StartTime', title: 'Od' },
                endTime: { name: 'EndTime', title: 'Do' },
            } }}
            
         eventRendered={this.onEventRendered.bind(this)} popupClose={this.onPopupClose} popupOpen={this.onPopupOpen.bind(this)} quickInfoTemplates={{content: this.contentTemplate.bind(this)}}  group={{ resources: ['Cars'] }} rowAutoHeight={true} 
         timeScale={{ enable: true, interval: 1440, slotCount: 2 }} 
         resizeStop={this.onResizeStop}
         resizeStart={this.onResizeStart}
         dragStop={this.onDragStop}
         dragStart={this.onDragStart}
         HeaderHeight="32">
        <ViewsDirective>
            <ViewDirective
                option='TimelineDay'
                interval={33}
            />
        </ViewsDirective>
        
        <ResourcesDirective>
            <ResourceDirective field='CarId' title='Stavka Najma' name='Cars' dataSource={this.state.filteredResources} textField='text' idField='id' descField='desc'>
            </ResourceDirective>
        </ResourcesDirective>


        <Inject services={[TimelineViews, TimelineMonth, Resize, DragAndDrop]}/>
    </ScheduleComponent>
            
    <div>
                <h1>Broj slobodnih najmova za danas:</h1>
                <div>
                    {this.tipoviNajma.map((tip, index) => (
                        <span key={index} style={{ marginRight: '20px' }}>
                            {tip}: {availableRentals[tip] || 0}
                        </span>
                    ))}
                </div>
                {/* Your existing filter buttons and other components here */}
    </div>


    
    
    

    </div>;
    }
}

export default Timeline;
