import { collection, getDocs, query, where, doc, updateDoc } from "firebase/firestore"; // Pretpostavka je da koristiš Firebase
import {db , storage} from '../firebase'
export async function getTodayEvents() {
    const today = new Date();
    const startOfDay = new Date(today.setHours(0, 0, 0, 0));
    const endOfDay = new Date(today.setHours(23, 59, 59, 999));

    const dataRef = collection(db, "EventData");

    // Dohvati događaje koji počinju danas
    const pocetakQuerySnapshot = await getDocs(query(dataRef, 
        where("StartTime", ">=", startOfDay), 
        where("StartTime", "<=", endOfDay)
    ));
    const todayPocetakEvents = pocetakQuerySnapshot.docs.map(doc => doc.data());
    
    // Dohvati događaje koji završavaju danas
    const krajQuerySnapshot = await getDocs(query(dataRef, 
        where("EndTime", ">=", startOfDay), 
        where("EndTime", "<=", endOfDay)
    ));
    const todayKrajEvents = krajQuerySnapshot.docs.map(doc => doc.data());

    return { pocetakEvents: todayPocetakEvents, krajEvents: todayKrajEvents };
}



export async function getTodayEventsWithCars() {
    const today = new Date();
    const startOfDay = new Date(today.setHours(0, 0, 0, 0));
    const endOfDay = new Date(today.setHours(23, 59, 59, 999));

    const eventDataRef = collection(db, "EventData");
    const carDataRef = collection(db, "Cars");

    // Dohvati događaje koji počinju danas
    const pocetakQuerySnapshot = await getDocs(query(eventDataRef, 
        where("StartTime", ">=", startOfDay), 
        where("StartTime", "<=", endOfDay)
    ));
    const todayPocetakEvents = pocetakQuerySnapshot.docs.map(doc => doc.data());

    // Dohvati događaje koji završavaju danas
    const krajQuerySnapshot = await getDocs(query(eventDataRef, 
        where("EndTime", ">=", startOfDay), 
        where("EndTime", "<=", endOfDay)
    ));
    const todayKrajEvents = krajQuerySnapshot.docs.map(doc => doc.data());

    // Kombiniraj sve događaje (početak i kraj)
    const allEvents = [...todayPocetakEvents, ...todayKrajEvents];

    // Dohvati sve jedinstvene CarId iz događaja
    const carIds = [...new Set(allEvents.map(event => event.CarId))];

    // Ako nema automobila za dohvatiti, vrati događaje bez automobila
    if (carIds.length === 0) {
        return { pocetakEvents: todayPocetakEvents, krajEvents: todayKrajEvents };
    }

    // Dohvati automobile na temelju CarId
    const carQuerySnapshot = await getDocs(query(carDataRef, where("id", "in", carIds)));
    const cars = carQuerySnapshot.docs.map(doc => doc.data());

    // Mapiraj CarId na odgovarajući automobil
    const carMap = {};
    cars.forEach(car => {
        carMap[car.id] = car; // Stvaramo mapu: CarId => CarObject
    });

    // Kombiniraj podatke događaja s informacijama o automobilima
    const combinedPocetakEvents = todayPocetakEvents.map(event => ({
        ...event,
        car: carMap[event.CarId] || {} // Dodaj podatke o automobilu
    }));

    const combinedKrajEvents = todayKrajEvents.map(event => ({
        ...event,
        car: carMap[event.CarId] || {} // Dodaj podatke o automobilu
    }));

    return { pocetakEvents: combinedPocetakEvents, krajEvents: combinedKrajEvents };
}

export async function getEventsWithCarsForDate(selectedDate) {
    const startOfDay = new Date(selectedDate.setHours(0, 0, 0, 0));
    const endOfDay = new Date(selectedDate.setHours(23, 59, 59, 999));

    const eventDataRef = collection(db, "EventData");
    const carDataRef = collection(db, "Cars");

    // Fetch events that start on the selected date
    const pocetakQuerySnapshot = await getDocs(query(eventDataRef, 
        where("StartTime", ">=", startOfDay), 
        where("StartTime", "<=", endOfDay)
    ));
    const pocetakEvents = pocetakQuerySnapshot.docs.map(doc => doc.data());

    // Fetch events that end on the selected date
    const krajQuerySnapshot = await getDocs(query(eventDataRef, 
        where("EndTime", ">=", startOfDay), 
        where("EndTime", "<=", endOfDay)
    ));
    const krajEvents = krajQuerySnapshot.docs.map(doc => doc.data());

    // Combine all events (start and end)
    const allEvents = [...pocetakEvents, ...krajEvents];

    // Get all unique CarId from events
    const carIds = [...new Set(allEvents.map(event => event.CarId).filter(id => id))];

    // If there are no cars to fetch, return events without cars
    if (carIds.length === 0) {
        return { pocetakEvents, krajEvents };
    }

    // Split carIds into chunks of max size 10
    function chunkArray(array, size) {
        const chunkedArr = [];
        for (let i = 0; i < array.length; i += size) {
            chunkedArr.push(array.slice(i, i + size));
        }
        return chunkedArr;
    }

    const carIdsChunks = chunkArray(carIds, 10);

    // Fetch cars based on CarId in chunks
    const cars = [];

    for (const chunk of carIdsChunks) {
        const carQuerySnapshot = await getDocs(query(carDataRef, where("id", "in", chunk)));
        cars.push(...carQuerySnapshot.docs.map(doc => doc.data()));
    }

    // Map CarId to corresponding car
    const carMap = {};
    cars.forEach(car => {
        carMap[car.id] = car; // Create map: CarId => CarObject
    });

    // Combine event data with car information
    const combinedPocetakEvents = pocetakEvents.map(event => ({
        ...event,
        car: carMap[event.CarId] || {} // Add car data
    }));

    const combinedKrajEvents = krajEvents.map(event => ({
        ...event,
        car: carMap[event.CarId] || {} // Add car data
    }));

    return { pocetakEvents: combinedPocetakEvents, krajEvents: combinedKrajEvents };
}

















async function updateAutomobilVrstaToUpperCase() {
    try {
        // Query to get all documents where vrsta is "Automobil"
        const q = query(collection(db, "Cars"), where("vrsta", "==", "Automobil"));
        
        // Execute the query and get the documents
        const querySnapshot = await getDocs(q);

        // Loop through each document and update the vrsta field to uppercase
        querySnapshot.forEach(async (document) => {
            const docRef = doc(db, "Cars", document.id); // Reference to the document
            await updateDoc(docRef, {
                vrsta: "AUTOMOBIL"
            });
            console.log(`Updated document ID: ${document.id} with vrsta: AUTOMOBIL`);
        });
        
        alert("Successfully updated all 'Automobil' types to uppercase.");
    } catch (error) {
        console.error("Error updating documents: ", error);
        alert("Error updating documents. Check the console for details.");
    }
}